import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/simple-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "/lesson-plans/"
      }}>{`»`}{` Back to Lesson Plans`}</a></p>
    <p>{`This week make `}<strong parentName="p">{`at least 5 substantive posts`}</strong>{` for this lesson's threads as follows:`}</p>
    <ul>
      <li parentName="ul">{`Reply to at least 3 of the numbered questions posed in the threads below `}<strong parentName="li">{`by 5pm Tuesday.`}</strong></li>
      <li parentName="ul">{`Then keep an eye on these and the other threads and respond to other students' posts or the professor's new questions with at least 2 more replies `}<strong parentName="li">{`by 5pm Friday.`}</strong></li>
    </ul>
    <h2>{`8.1 Reflex and Convention`}</h2>
    <p>{`In interface design, what is the relationship between reflex and convention?`}</p>
    <h2>{`8.2 The Important Stuff`}</h2>
    <p>{`Call out a design principle you’re familiar with from other courses or contexts that comes to mind when you read that “successful interfaces are those in which users immediately notice the important stuff.” Tie this principle to decisions made in one of the preceding planes according to Garrett's model.`}</p>
    <h2>{`8.3 Obviously Subtle`}</h2>
    <p>{`What are some techniques you’ve seen applied that allow common features to be most apparent while also making less frequent features easy to find as well? Ideally, provide a real world example.`}</p>
    <h2>{`8.4 Complex Yet Easy`}</h2>
    <p>{`What is the most complex interface you’ve used that you also found very easy to understand? Why do you think this was the case?`}</p>
    <h2>{`8.5 Do Hard Stuff... Like Navigation Design`}</h2>
    <p>{`Why is navigation design not as simple as you might think based on what Garrett explained in Chapter 6? How is this related to conventions, reflex, and conceptual models?`}</p>
    <h2>{`8.6 Applying Information Design`}</h2>
    <p>{`Describes some ways that the broad concept of information design applies to either navigation design or interface design.`}</p>
    <h2>{`8.7 Wireframes`}</h2>
    <p>{`Describe the wireframe and how it is used according to Garrett. How does this compare to your understanding of wireframes from other classes or contexts?`}</p>
    <h2>{`8.8 Sensory Design`}</h2>
    <p>{`The author calls out the surface layer as involving sensory design. How does this terminology play out and how does this impact your understanding of the designer’s role in the process?`}</p>
    <h2>{`8.9 Eye tracking`}</h2>
    <p>{`What is eye tracking and what are some patterns the author discusses for effective considerations of the results of such a practice?`}</p>
    <h2>{`8.10 Design Principles`}</h2>
    <p>{`Choose one of the design principles Garett describes in Chapter 7 and apply it to critique either a successful or challenged website you visit frequently.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      